<template>
  <div class="cs-container" style="background-color: white">
    <b-overlay
      :show="loading"
      :variant="`white`"
      :opacity="0.75"
      :blur="``"
      rounded="none"
    >
      <div
        class="d-print-none mb-2 flex items-center justify-end"
        style="margin-right: 80px"
      >
        <b-button class="mr-2" variant="primary" @click="handlePrint"
          >In Phiếu</b-button
        >
        <b-button variant="primary" @click="handleAttach"
          >Đính kèm phiếu</b-button
        >
        <span class="close-icon d-print-none" @click="backToDetail"
          >&times;</span
        >
      </div>

      <div ref="documentPrint" v-html="renderedContent"></div>
    </b-overlay>
    <div class="dot-spinner" v-if="loading">
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
      <div class="dot-spinner__dot"></div>
    </div>
  </div>
</template>
  
<script>
import { mapState } from 'vuex'
import { templateHTMLPrint, base64 } from '@/utils/printUtils'
import appUtils from '@/utils/appUtils'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PrintOrderConsentForm',
  components: { ToastificationContent },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
      consentForm: (state) => state.orders.consentForm
    }),
    full_name() {
      return (
        this.consentForm.full_name && this.consentForm.full_name.toUpperCase()
      )
    },
    guardian_full_name() {
      return (
        this.consentForm.guardian_full_name &&
        this.consentForm.guardian_full_name.toUpperCase()
      ) || ""
    },
    renderedContent() {
      return this.replacePlaceholders(this.$route.params.html)
    }
  },
  methods: {
    replacePlaceholders(str) {
      return str.replace(/{{\s*([^}\s]+)\s*}}/g, (match, p1) => {
        if (p1.includes('.')) {
          let keys = p1.split('.')

          // Regular expression to match strings between %{value_of_checkbox}% to get Checkbox/Radio value
          let regexCheckbox = /%\s*(.*?)\s*%/g
          let matches = keys[0].match(regexCheckbox)
          if (matches && matches.length) {
            keys.splice(0, 1)
            let checkboxValue = matches[0].replace(/%\s*(.*?)\s*%/, '$1')
            let value = this
            for (const key of keys) {
              if (value && (value.hasOwnProperty(key) || value[key])) {
                value = value[key]
              } else {
                value = '' // Property not found
              }
            }
            if (value && value.length) {
              if (value.includes(+checkboxValue)) return 'checked'
            }
            if (value == checkboxValue) return 'checked'
            else return ''
          }

          // Regular expression to match strings between !{index}! to get Array value
          let regexArr = /!\s*(.*?)\s*!/g
          matches = keys[0].match(regexArr)
          if (matches && matches.length) {
            keys.splice(0, 1)
            let indexArr = matches[0].replace(/!\s*(.*?)\s*!/, '$1')
            let value = this
            if (value[keys[0]][keys[1]]?.[indexArr]) {
              value = value[keys[0]][keys[1]]?.[indexArr]?.[keys[2]]
            } else {
              value = ''
            }
            return value || ""
          }

          // Regular expression to match strings between &...& to get Date value
          let regexDate = /&\s*(.*?)\s*&/g
          matches = keys[0].match(regexDate)
          if (matches && matches.length) {
            keys.splice(0, 1)
            let value = this
            for (const key of keys) {
              if (value && (value.hasOwnProperty(key) || value[key])) {
                value = value[key]
              } else {
                value = '' // Property not found
              }
            }
            return this.handleFormatDate(value)
          }

          let value = this
          for (const key of keys) {
            if (value && (value.hasOwnProperty(key) || value[key])) {
              value = value[key]
            } else {
              return '' // Property not found
            }
          }
          return value || ""
        }
        return this?.[p1] !== undefined ? this?.[p1] : match
      })
    },
    handlePrint() {
      document.title = 'Consent-form'
      window.print()
    },
    async handleAttach() {
      this.loading = true
      try {
        const self = this
        const bodyHTML = self.$refs.documentPrint.innerHTML
        const tempHTML = templateHTMLPrint({ bodyHTML })
        const base64Data = base64().decode(tempHTML)
        await this.savePdfToS3(base64Data)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async savePdfToS3(base64) {
      if (!this.$route.params.orderItem?.id) return
      try {
        const payload = {
          content: base64,
          fileName: `${appUtils.slugifyStr(
            `${this.$route.params.form.form_name}_${this.$route.params.orderItem?.product_name?.trim()}_${Date.now()}`,
            '_'
          )}_`,
          path: 'supplier',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf
          .getRequest('CommonRequest')
          .printDocument(payload)

        const url = res.data?.documentLink

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_the_result_form_has_been_generated'),
            icon: 'CheckIcon',
            variant: 'success'
          }
        })
        this.backToDetail(null, url)
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_failed_to_generate_the_result_form'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    backToDetail(e, url = '') {
      document.title = 'HODO partner'
      this.$router.replace({
        name: 'orderDetail',
        params: {
          id: this.orders?.viewDetail?.id || this.$route.params.id,
          url: url,
          item_id: this.$route.params.id,
          form_id: this.$route.params.form.id
        },
        query: {
          step: 0
        }
      })
    },
    handleFormatDate(date) {
      if (!date) return ''
      return moment(date).format('DD-MM-YYYY')
    }
  }
}
</script>
  
<style lang="scss">
.dot-spinner {
  --uib-size: 40px;
  --uib-speed: 0.9s;
  --uib-color: white;
  position: absolute;
  top: 50vh;
  right: 50%;
  transform: translate(50%, 50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse calc(var(--uib-speed) * 1.111) ease-in-out infinite;
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    font-size: 18px;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type='checkbox'] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: rgb(196, 91, 19);
  font-size: 36px !important;
}

@media print {
  body,
  html {
    height: 100vh;
    * {
      color: black !important;
    }
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>
<style lang="scss">
.require-form {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
      font-size: 18px;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .w-90 {
      width: 90%;
    }
    .w-80 {
      width: 80%;
    }
    .w-30 {
      width: 30%;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .container-border {
    border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px;
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
    .flex-grow-4 {
      flex-grow: 4;
    }
    .flex-grow-2 {
      flex-grow: 2;
    }
    &.gap-0 {
      gap: 0px;
    }
  }

  // .cs-label {
  // width: 150px;
  // }
  .bold {
    font-weight: bold;
  }

  .cs-border-b {
    border-bottom: 1px solid #5c5c5c;
  }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: baseline;
    input {
      transform: translateY(3px);
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }
  .mh-100 {
    min-height: 100px;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    color: #0569c5;
    font-size: 32px !important;
  }

  @media print {
    body,
    html {
      height: 100vh;
      * {
        color: black !important;
      }
    }
    .text-white {
      color: white !important;
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.gen-test-form {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
      font-size: 18px;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .w-90 {
      width: 90%;
    }
    .w-80 {
      width: 80%;
    }
    .w-30 {
      width: 30%;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .container-border {
    border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1px 6px;
    border: 1px solid #5c5c5c;
    background: #0569c5;
    color: white;
    .text-normal {
      color: white;
      text-transform: lowercase;
    }
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
    .flex-grow-4 {
      flex-grow: 4;
    }
    .flex-grow-2 {
      flex-grow: 2;
    }
  }

  // .cs-label {
  // width: 150px;
  // }

  .cs-border-b {
    border-bottom: 1px solid #5c5c5c;
  }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: baseline;
    input {
      transform: translateY(3px);
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #0569c5;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }
  .w-full {
    width: 100%;
  }
  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    font-size: 32px !important;
    color: #0569c5;
  }
  .text-red {
    color: red;
    * {
      color: red;
    }
    input.uncheck {
      outline: 1px solid red;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
    }
  }
  .text-blue {
    color: #0569c5;
    * {
      color: #0569c5;
    }
    input.uncheck {
      outline: 1px solid #0569c5;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
    }
  }
  .text-yellow {
    color: #d6da21;
    * {
      color: #d6da21;
    }
    input.uncheck {
      outline: 1px solid #d6da21;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
    }
  }
  .w-70 {
    max-width: 80%;
  }
  @media print {
    body,
    html {
      height: 100vh;
      * {
        color: black !important;
      }
    }
    .cs-title {
      color: white !important;
      .text-normal {
        color: white !important;
      }
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.g4500-form {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .bottom {
      margin-bottom: 10px;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .order-list {
    list-style-type: disc;
  }

  .container-border {
    border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .part-4 {
    margin-top: 6px;
  }

  // .html2pdf__page-break {
  //   page-break-before: always;
  // }
  .box {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .left {
    margin-left: 1px;
  }

  .container-border-2 {
    border-bottom: 1px solid #5c5c5c;
    border-left: 1px solid #5c5c5c;
    border-right: 1px solid #5c5c5c;
    width: 100%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px;
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
  }

  .cs-flex-2 {
    display: flex;
    margin-left: 6px;
    box-sizing: border-box;
    align-items: center;
  }

  .cs-label {
    width: 150px;
  }

  .cs-border-b {
    border-bottom: 1px solid #5c5c5c;
  }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }
  .none-border-bottom {
    border-bottom: none;
  }
  .none-border-right {
    border-right: none;
  }
  .none-border-left {
    border-left: none;
  }
  .none-border-top {
    border-top: none;
  }
  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: center;

    input {
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 20px;
    font-weight: bold;
    background-color: #0569c5;
    color: #fff;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    color: #0569c5;
    font-size: 36px !important;
    text-align: center;
  }

  @media print {
    body,
    html {
      height: 100vh;
      background-color: #fff !important;
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.requirement-form {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
      font-size: 18px;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .w-90 {
      width: 90%;
    }
    .w-80 {
      width: 80%;
    }
    .w-30 {
      width: 30%;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .container-border {
    border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px;
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
    .flex-grow-4 {
      flex-grow: 4;
    }
    .flex-grow-2 {
      flex-grow: 2;
    }
    &.gap-0 {
      gap: 0px;
    }
  }

  // .cs-label {
  // width: 150px;
  // }
  .bold {
    font-weight: bold;
  }

  // .cs-border-b {
  //   border-bottom: 1px solid #5c5c5c;
  // }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: baseline;
    input {
      transform: translateY(3px);
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }
  .mh-100 {
    min-height: 100px;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    color: #0569c5;
    font-size: 32px !important;
  }

  @media print {
    body,
    html {
      height: 100vh;
      * {
        color: black !important;
      }
    }
    .text-white {
      color: white !important;
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.xet-nghiem-dot-bien-gen {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    margin-top: 6rem;

    .right {
      margin-left: 30rem;
    }
  }
  .container-border {
    // border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .above {
    margin-top: 5rem;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }
  .part-6 {
    margin-top: 1.5rem;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3px;
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
  }

  // .cs-label {
  //   width: 150px;
  // }

  // .cs-border-b {
  //   border-bottom: 1px solid #5c5c5c;
  // }
  // .cs-border-t {
  //   border-top: 1px solid #5c5c5c;
  // }

  .letter {
    font-size: 18px;
  }

  .flex-letter {
    display: flex;
    flex-direction: row;
    .flex-left {
      display: flex;
      flex-direction: column;
    }

    .flex-right {
      display: flex;
      flex-direction: column;
    }
  }

  .check-box-master {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .check-box-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .check-box-2 {
      display: flex;
      flex-direction: column;
    }
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: center;

    input {
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .tki-section {
    margin-bottom: 2rem;
  }
  .cs-treatment {
    margin-left: 15rem;
  }
  .cs-sample {
    flex: 3;
  }

  .cs-smoke {
    margin-left: 7rem;
  }

  .cs-check {
    margin-left: 7rem;
  }
  .cs-email {
    margin-right: 24rem;
  }

  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    // border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    color: #0569c5;
    font-size: 36px !important;
  }

  @media print {
    body,
    html {
      height: 100vh;
      background-color: #fff;
      * {
        color: black !important;
      }
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.blood-sample-form {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
      font-size: 18px;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .w-90 {
      width: 90%;
    }
    .w-80 {
      width: 80%;
    }
    .w-30 {
      width: 30%;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .container-border {
    border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1px 6px;
    border: 1px solid #5c5c5c;
    background: #0569c5;
    color: white;
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
    .flex-grow-4 {
      flex-grow: 4;
    }
    .flex-grow-2 {
      flex-grow: 2;
    }
    &.gap-0 {
      gap: 0px;
    }
  }

  // .cs-label {
  // width: 150px;
  // }
  .bold {
    font-weight: bold;
  }

  .cs-border-b {
    border-bottom: 1px solid #5c5c5c;
  }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: baseline;
    input {
      transform: translateY(3px);
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    color: rgb(196, 91, 19);
    font-size: 36px !important;
  }

  @media print {
    body,
    html {
      height: 100vh;
      * {
        color: black !important;
      }
    }
    .cs-title {
      color: white !important;
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.nipt-form {
  .w-90 {
    width: 90%;
  }
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .space-letter {
    margin-top: 10px;
    gap: 5px;
  }

  .container-border {
    // border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px;
    background-color: #0569c5;
    color: #ffffff;
  }

  .cs-flex {
    display: flex;
    // gap: 20rem;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
  }

  .cs-flex-2 {
    display: flex;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
  }

  // .part-four {
  //   margin-top: 30rem;
  // }
  .cs-flex-3 {
    display: flex;
    // flex-direction: row;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
  }

  .cs-flex-4 {
    display: flex;
    box-sizing: border-box;
    align-items: center;
  }
  .cs-flex-extra {
    display: flex;
    flex-direction: column;
    padding: 6px;
    box-sizing: border-box;
  }

  .left {
    gap: 200px;
    list-style-type: circle;
  }

  .cs-label {
    width: 150px;
  }

  .cs-label-extra {
    width: 300px;
  }

  .letter {
    display: flex;
    margin-left: 7px;
    box-sizing: border-box;
    align-items: center;
  }
  .cs-label-2 {
    width: 150px;
    font-weight: bold;
  }

  .just-title {
    width: 150px;
  }

  .cs-label-3 {
    width: 260px;
    margin-right: 60px;
    padding: 6px;
  }

  .cs-label-4 {
    margin-left: 220px;
  }

  .order {
    // gap: 200px;
    list-style-type: circle;
    gap: 150px;
  }

  .list {
    list-style-type: circle;
  }

  .list-2 {
    list-style-type: circle;
    margin-left: 9px;
  }

  .cs-border-b {
    border-bottom: 1px solid #5c5c5c;
  }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: center;
    margin-top: 5px;

    input {
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-checkbox-1 {
    pointer-events: none;
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-top: 6px;
    input {
      width: 18px;
      height: 18px;
      // margin-left: 50px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-checkbox-2 {
    pointer-events: none;
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-top: 6px;
    // input {
    //   width: 18px;
    //   height: 18px;
    //   margin-left: 50px;
    // }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .blood-day {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .cs-content-1 {
    display: flex;
    flex-direction: row;
  }

  .cs-content-2 {
    margin-left: 265px;
  }
  .cs-content-3 {
    margin-left: 290px;
  }

  .cs-content-extra {
    margin-left: 5px;
    font-weight: bold;
  }
  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    color: #0569c5;
    font-size: 24px !important;
  }

  .tiny-text {
    text-align: center;
    margin-top: 10px;
  }

  .consultant {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .left-side {
    margin-left: 30px;
  }

  .right-side {
    margin-left: 60px;
  }

  @media print {
    body,
    html {
      height: 100vh;
      background-color: #fff !important;
    }
    .text-danger,
    .title-color {
      color: black !important;
    }
    input[type='checkbox'] {
      accent-color: #20419b;
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
.cancer-form {
  .cs-container {
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    * {
      color: black;
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
      font-size: 18px;
    }

    #printOrderForm {
      margin: auto;
      // margin-top: 100px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .w-90 {
      width: 90%;
    }
    .w-80 {
      width: 80%;
    }
    .w-30 {
      width: 30%;
    }
    .w-20 {
      width: 20%;
    }

    h2 {
      // color: black;
      font-size: 28px;
    }
  }

  .container-border {
    border: 1px solid #5c5c5c;
    padding: 0;
    width: 50%;
  }

  .cs-title {
    display: block;
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1px 6px;
    border: 1px solid #5c5c5c;
    background: #0569c5;
    color: white;
  }

  .cs-flex {
    display: flex;
    gap: 8px;
    padding: 6px;
    box-sizing: border-box;
    align-items: center;
    .flex-grow-4 {
      flex-grow: 4;
    }
    .flex-grow-2 {
      flex-grow: 2;
    }
  }

  // .cs-label {
  // width: 150px;
  // }

  .cs-border-b {
    border-bottom: 1px solid #5c5c5c;
  }
  .cs-border-t {
    border-top: 1px solid #5c5c5c;
  }

  .cs-border-l {
    border-left: 1px solid #5c5c5c;
  }

  .cs-border-r {
    border-right: 1px solid #5c5c5c;
  }

  .cs-width-full {
    width: 100% !important;
  }

  .cs-font-bold {
    font-weight: bold;
  }

  .cs-checkbox {
    pointer-events: none;
    display: flex;
    align-items: baseline;
    input {
      transform: translateY(3px);
      width: 18px;
      height: 18px;
    }

    input[type='checkbox'] {
      accent-color: #20419b;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 6px;
    }
  }

  .cs-content {
    flex: 1;
  }

  .min-checkbox {
    min-width: 120px;
  }
  .box-content {
    display: block;
    padding: 6px;
    min-height: 120px;
  }
  .html2pdf__page-break {
    page-break-before: always;
  }

  .cs-padding {
    padding: 6px;
  }

  .cs-border {
    border: 1px solid #5c5c5c;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dashed;
  }

  .cs-text-sm {
    font-size: 11px;
    font-weight: 500;
  }

  .cs-text-md {
    font-size: 16px;
  }

  .cs-list {
    display: flex;
    gap: 12px;
  }

  .cs-ol {
    li {
      position: relative;
      padding-left: 10px;
      margin-left: 15px;
    }

    li:before {
      position: absolute;
      left: -2px;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
    font-size: 40px;
    cursor: pointer;
  }

  .cs-img {
    width: 300px;
  }

  .title-color {
    font-size: 32px !important;
  }

  @media print {
    body,
    html {
      height: 100vh;
      * {
        color: black !important;
      }
    }

    .hidden-print {
      border: none;
      outline: none;
      background: transparent;
    }

    #BrowserPrintDefaults {
      display: none;
    }

    #printOrderForm {
      max-width: 1000px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .html2pdf__page-break {
      page-break-before: always;
    }

    @page {
      margin: 0;
    }
  }
}
</style>